<template>
  <div class="dev-container">
    Dev
    <Chip cloudant="2" mysql="1" />
    <Chip type="WARN" cloudant="2" mysql="1" />
    <Chip type="ERROR" cloudant="2" mysql="1" />
    <Chip type="ERROR" cloudant="2" />

    <StateIndicator state="ERROR" />
    <StateIndicator state="WARN" />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import Chip from '@/components/elements/Chip.vue'
import StateIndicator from '@/components/elements/StateIndicator.vue'

@Options({
  name: 'Dev',
  components: {
    Chip,
    StateIndicator,
  },
})
export default class Dev extends Vue {}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';
.dev-container {
  padding-top: 100px;
  min-height: 100vh;
}
</style>
