
import { Vue, Options } from 'vue-class-component'
import Chip from '@/components/elements/Chip.vue'
import StateIndicator from '@/components/elements/StateIndicator.vue'

@Options({
  name: 'Dev',
  components: {
    Chip,
    StateIndicator,
  },
})
export default class Dev extends Vue {}
